/*
This file contains a dark-mode compatible TailwindCSS component that renders the new official DOE Logo (2025) as an image.

The logo uses the Horizontal Condensed arrangement as specified in the 2025 brand guidelines.
When in dark mode, it uses the official reverse colorway version.

Make sure your tailwind config file contains the new DOE brand colors defined in the tailwind folder.
*/

import { cn } from "@/app/_utils"
import Image from "next/image"

export function DOELogo({ className }: { readonly className?: string }) {
  return (
    <div className={cn("w-[180px]", className)}>
      {/* Official DOE Horizontal Condensed Logo 2025 */}
      <Image
        src="/img/white_new_logo.svg"
        alt="U.S. Department of Energy Logo"
        width={180}
        height={45}
        className="h-auto w-full"
        priority
      />
    </div>
  )
}
