import Link from "next/link"
import { DOELogo } from "./doeLogo"

export function PARSLogo() {
  return (
    <Link href="/">
      <div className="flex flex-row items-center">
        {/* DOE Logo with proper spacing */}
        <DOELogo className="ml-2 max-[420px]:w-[130px]" />

        {/* Vertical separator using new brand colors */}
        <div className="ml-4 h-10 w-[2px] bg-clear-300 max-[420px]:ml-2 dark:bg-clear-600" />

        {/* PARS text using serif font */}
        <h1 className="ml-4 mt-2 font-serif text-4xl font-bold tracking-tight text-nav-text max-[420px]:ml-2 max-[420px]:text-2xl dark:text-nav-text-dark">
          PARS
        </h1>
      </div>
    </Link>
  )
}
